<template>
  <div>
    <ValidationObserver v-slot:default="context">
      <div class="vx-row">
        <div class="vx-col w-full">
          <form @submit.prevent="submit(context)">
            <div class="vx-row">
              <div class="vx-col w-3/12 flex items-center mb-base">
                ชื่อลักษณะพิเศษ*
              </div>
              <div class="vx-col w-8/12 mb-base">
                <ValidationProvider
                  v-slot="{ errors }"
                  name="ชื่อลักษณะพิเศษ"
                  rules="required"
                >
                  <vs-input
                    v-model="formData.house_function_feature_title"
                    class="w-full"
                    name="house_function_feature_title"
                    placeholder="เช่น ติดตั้งเครื่องปรับอากาศ,ตกแต่งเฟอร์นิเจอร์"
                  />
                  <span 
                    v-if="errors.length > 0" 
                    class="text-danger text-sm">{{
                      errors[0]
                    }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col flex w-full justify-center">
                <vs-button 
                  class="mr-2" 
                  @click="submit(context)">{{
                    buttonLabel
                  }}</vs-button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
import { reactive, computed } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import useCrud from '@/use/useCrud'

export default {
  name: 'Form',
  components: {
    ValidationObserver,
  },
  props: {
    onCreateSuccess: {
      type: Function,
      default: () => {}
    },
    editHouseData: {
      type: Object,
      default: () => ({})
    },
  },
  setup(props, ctx) {
    const initialFormData = computed(() => {
      return {
        uuid: props.editHouseData.uuid,
        id: props.editHouseData.id,
        house_function_feature_title:
          props.editHouseData.house_function_feature_title || '',
      }
    })

    const { addItem } = useCrud(ctx, 'houseFunctionFeature', {
      fetchOnMount: false,
      onModifyItemSuccess: props.onCreateSuccess,
    })
    const formData = reactive(initialFormData)
    const submit = ({ validate }) => addItem({ validate }, formData.value)
    const buttonLabel = computed(() => {
      return formData.value.id ? 'แก้ไข' : 'เพิ่ม'
    })

    return {
      submit,
      formData,
      buttonLabel,
    }
  },
}
</script>
